/*
  check axios status 401 -> redirect to login page
*/
axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response.status === 401 || error.response.status === 403) {
      // remove user data from local storage
      localStorage.removeItem('user')

      window.location.href = '/login'
    }

    return Promise.reject(error)
  }
)
