<script setup>
import { ref, getCurrentInstance } from 'vue'
import { ArrowLeftStartOnRectangleIcon  } from '@heroicons/vue/24/outline'
import { useAuthStore } from '../../js/store/auth';
import ConfirmModal from './ConfirmModal.vue';

const $root = getCurrentInstance()?.appContext.config.globalProperties
const authStore = useAuthStore()

const confirmModal = ref(null)

const logout = async () => {
  $root.overlay.loading = true
  await authStore.logout()
  $root.overlay.loading = false

  window.location.href = '/login'
}
</script>
<template>
  <div class="flex justify-between items-center px-4 py-1 bg-white shadow-md">
    <h3>{{ authStore.username }}</h3>
    <button 
      type="button" 
      class="flex flex-col justify-center items-center group text-sblue cursor-pointer hover:opacity-80"
      @click.prevent="confirmModal.open()"
    >
      <ArrowLeftStartOnRectangleIcon class="w-5 h-5 rotate-180 group-hover:scale-110 transition-all delay-75 duration-300 ease-in-out" />
      ログアウト
    </button>
  </div>

  <ConfirmModal 
    ref="confirmModal"
    title="ログアウト"
    message="ログアウトしますか？"
    :confirm="logout"
  />
</template>
