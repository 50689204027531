import './bootstrap'
import './error'

// Vuejs
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createNotivue } from 'notivue'
import route from './route'

import 'notivue/notification.css'
import 'notivue/notification-progress.css'
import 'notivue/animations.css'

import App from '../views/App.vue'

const pinia = createPinia()

createApp(App)
  .use(createNotivue({ 
    position: 'top-right', 
    duration: 5000,
    pauseOnHover: false,
  }))
  .use(pinia)
  .use(route)
  .mount('#app')
