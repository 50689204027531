<script setup>
import { ref } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from '@headlessui/vue'

const isOpen = ref(false)
const open = () => {
  isOpen.value = true
}
const close = () => {
  isOpen.value = false
}

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  message: {
    type: String,
    default: ''
  },
  confirm: {
    type: Function,
    required: true
  },
  textOk: {
    type: String,
    default: 'OK'
  },
  textCancel: {
    type: String,
    default: 'キャンセル'
  }
})

const doConfirm = () => {
  props.confirm()
  close()
}

defineExpose({ open, close })
</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="max-w-fit min-w-80 transform overflow-hidden bg-graysp text-left align-middle shadow-xl transition-all p-4 rounded-lg"
            >
              <h3 class="font-bold">{{ props.title }}</h3>
              <p v-if="props.message" class="mt-4">{{ props.message }}</p>
              <div class="flex justify-center mt-6">
                <button
                  type="button"
                  class="px-4 py-2 text-white bg-primary rounded-md hover:bg-primary-dark min-w-28"
                  @click="doConfirm"
                >
                  {{ props.textOk }}
                </button>
                <button
                  type="button"
                  class="px-4 py-2 text-white bg-gray-500 rounded-md ml-2 hover:bg-gray-600"
                  @click="close"
                >
                  {{ props.textCancel }}
                </button>
              </div> 
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
