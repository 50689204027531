<script setup>
import { ref, watch, getCurrentInstance, onMounted } from 'vue'
import { Notivue, Notification, NotificationProgress, push } from 'notivue'
import { useRoute, useRouter } from 'vue-router'
import OverlayVue from './components/Overlay.vue'
import { useNavigationStore } from '../js/store/navigation'

import Header from './components/Header.vue'
import Navigation from './components/Navigation.vue'

const navigationStore = useNavigationStore()

const refOverlay = ref(null)
const $route = useRoute()
const router = useRouter()
const $root = getCurrentInstance()?.appContext.config.globalProperties

let title = ref('')

onMounted(() => {
  if ($root) {
    $root.push = push
    $root.router = router
    $root.overlay = refOverlay.value
  }

  title.value = $route.meta.title
})

watch($route, (to, from) => {
  if ($root) {
    // $root.push.clearAll()
    $root.overlay.loading = false
  }

  navigationStore.setActive(to.path)
  
  title.value = to.meta.title
})

</script>
<template>
  <div class="flex h-screen w-screen bg-background overflow-hidden">
    <Navigation />
    <div class="grow">
      <div class="flex flex-col h-screen">
        <Header />
        <div class="flex flex-col p-4 h-full">
          <div v-if="title" class="flex items-center justify-between">
            <h2 class="font-bold">
              {{ title }}
            </h2>
          </div>
          <div class="flex h-full bg-white rounded-md wap-content mt-2">
            <router-view/>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Notivue v-slot="item">
    <Notification :item="item">
      <NotificationProgress :item="item" />
    </Notification>
  </Notivue>
  <OverlayVue ref="refOverlay" />
</template>
<style>
.wap-content{
  height: calc(100vh - 100px);
  max-height: calc(100vh - 100px);
  overflow: auto;
}
.wap-content > div{
  overflow-y: auto;
}
</style>